@font-face {
	font-family : 'Pretendard';
	src: local('Pretendard Variable'), url('./assets/fonts/PretendardVariable.woff2') format('woff2'), url('./assets/fonts/PretendardVariable.woff') format('woff');
	font-display: 'optional';
	font-weight: 100 900;
}
@font-face{
	font-family: 'Miama';
	font-display : block;
	src : local('Miama'), url('./assets/fonts/Miama.ttf') format('truetype'), url('./assets/fonts/Miama.otf') format('opentype');
}